import React, { useEffect } from "react";
import { Hero } from "../components/Hero";
import image from "../assets/images/pexels-tom-fisk-3856433.jpg";
import { Subscribe } from "../components/Subscribe";
import { Categories } from "../components/Categories";

export const ProductsList = (props) => {
    useEffect(() => {
        document.title = props.title;
    }, [props.title])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <Hero
                heroImage={image}
                title="Our products"
                subTitle="A wide range of cleaning equipment from your everyday cleaning task to the most challenging job. Here you can find not only the high pressure water blasting machine for your needs but also safety gears protection clothing (PPE), tumble boxes, foot valves, flow divider valves, wet sandblasting kits and more."
            />

            <Categories products={props.products} />

            <Subscribe path='/contacts' btn='Get in touch' title="Contact us to get your offer!" subTitle='Click the button below and ask us about all the additional information you may need. We would be more than happy to assist you!' />
        </>
    );
};
