import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export const FormComponent = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_56gctrc', 'template_deyh5fi', form.current, 'Fddu0AQ8XI4alpAN1')
        .then((result) => {
            alert('Inquiry successfully sent!')
            e.target.reset();
        }, (error) => {
            alert('Could not sent the inquriy! Please check all the fields and try again.')
        });
    };
    return (
        <Container>
            <section className="section_form">
                <h2 className="text-center">Get in touch with us</h2>

                <ScrollAnimation animateIn="animate__bounceInLeft" animateOut="animate__bounceOutLeft" animateOnce={true}>
                    <form ref={form} onSubmit={sendEmail}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="exampleName"> Full Name</Label>
                                    <Input
                                        id="exampleName"
                                        name="name"
                                        type="text"
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="exampleCompany">Email</Label>
                                    <Input
                                        id="exampleEmail"
                                        name="email"
                                        type="email"
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="examplePhone">Phone number</Label>
                                    <Input
                                        id="examplePhone"
                                        name="phone"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="exampleCompany">Company</Label>
                                    <Input
                                        id="exampleCompany"
                                        name="company"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>    
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="exampleAddress">Address</Label>
                                    <Input
                                        id="exampleAddress"
                                        name="address"
                                        placeholder="1234 Main St"
                                        type="text"
                                        required
                                    />
                                </FormGroup>
                            </Col>    
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="exampleCity">City</Label>
                                    <Input id="exampleCity" name="city" type="text" required />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label for="exampleZip">Zip</Label>
                                    <Input id="exampleZip" name="zip" type="text" required />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="exampleCountry">Country</Label>
                                    <Input id="exampleCountry" name="country" type="text" required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label for="exampleMessage">Message</Label>
                                <Input type="textarea" id="exampleMessage" name="message" placeholder="Type your message here..." required />
                            </FormGroup>
                        </Row>
                        <Button>Send</Button>
                    </form>
                </ScrollAnimation>
            </section>
        </Container>
    );
};
