import React, { useEffect } from "react";
import { Hero } from "../components/Hero";
import image from "../assets/images/cristian-palmer-3leBubkp5hk-unsplash.jpg";
import { FormComponent } from "../components/FormComponent";
import { Subscribe } from "../components/Subscribe";

export const Contacts = (props) => {
    useEffect(() => {
        document.title = props.title;
    }, [props.title])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Hero
                heroImage={image}
                title="Get an offer now!"
                subTitle="Fill the form below regarding any questions you may have about our products. We would be more than happy ot provide you with the necessary information."
            />

            <FormComponent />

            <Subscribe path='/products-list' btn='Go to products' title="Browse all our products here!" subTitle="Click the button below to have a second look at our products." />
        </>
    );
};
