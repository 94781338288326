import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./pages/Home";
import { ProductsList } from "./pages/ProductsList";
import { About } from "./pages/About";
import { Contacts } from "./pages/Contacts";
import { Root } from "./pages/Root";
import { Error } from "./pages/Error";
import { Product } from "./pages/Product";
import UJ10_170 from "./assets/images/products/ULTRAJET UJ10-170.png";
import UJ10_200 from "./assets/images/products/ULTRAJET UJ10-200.png";
import UJ20_350 from "./assets/images/products/ULTRAJET UJ20-350.png";
import UJ20_500 from "./assets/images/products/ULTRAJET UJ20-500.png";
import UJ40_1000 from "./assets/images/products/ULTRAJET UJ40-1000.png";
import UJ40_500 from "./assets/images/products/ULTRAJET UJ40-500.png";
import UJ40_800 from "./assets/images/products/ULTRAJET UJ40-800.png";

const PRODUCTS = [
    {
        id: "UJ10_170",
        image: UJ10_170,
        title: "ULTRAJET UJ10-170",
        description:
            "is a professional heavy-duty high-pressure washer with a high-performance industrial pump with ceramic pistons, oversized friction bearings and connecting rods in bronze, and with the simplest construction design – aimed to reduce the Total Cost of Ownership (TCOO). Fully designed and manufactured in Singapore, these industrial high pressure washers are designed to reduce the complexity of maintenance without compromising the quality and durability for heavy duty usage under the toughest conditions.",
        tableData: [
            ["Power supply", "3~/440/60 Hz", ""],
            ["Debit", "15", "l/min"],
            ["Work pressure", "170", "bar"],
            ["Max. Temperature of the inlet water (°C)", "65", ""],
            ["Power Consumption", "5.0", "kW"],
            ["Weight", "52", "Kg"],
            ["Dimensions: L x W x H", "780 x 500 x 680", "mm"],
        ],
        equipment: ['10m 4-core oil resistance electric cable with 4-pin plug', '10m wear resistance high pressure hose with stainless steel fittings', 'Trigger gun with double barrel lance, regulating valve and stainless-steel nozzle', 'User manual book with parts list'],
        highlights: ['Made in Singapore with EU pump', 'Sturdy full stainless-steel tubular frame with removable handle bar', 'Triplex ceramic plungers pump', 'Three-phase 5.5 kW high-efficiency IP55 Italian motor', 'Unloader valve with pressure release', 'Stainless steel pressure gauge', 'Quick connect inlet/outlet coupling', 'Weather-proof top switch panel with motor overload protection', 'Thermal protection valve to protect damage to pump seals'],
    },
    {
        id: "UJ10_200",
        image: UJ10_200,
        title: "ULTRAJET UJ10-200",
        description: "is a professional heavy-duty high-pressure washer with a high-performance industrial pump with ceramic pistons, oversized friction bearings and connecting rods in bronze, and with the simplest construction design – aimed to reduce the Total Cost of Ownership (TCOO). Fully designed and manufactured in Singapore, these industrial high pressure washers are designed to reduce the complexity of maintenance without compromising the quality and durability for heavy duty usage under the toughest conditions.",
        tableData: [
            ["Power supply", "3~/440/60 Hz", ""],
            ["Debit", "15", "l/min"],
            ["Work pressure", "200", "bar"],
            ["Max. Temperature of the inlet water (°C)", "65", ""],
            ["Power Consumption", "5.0", "kW"],
            ["Weight", "52", "Kg"],
            ["Dimensions: L x W x H", "780 x 500 x 680", "mm"],
        ],
        equipment: [
            "10m 4-core oil resistance electric cable with 4-pin plug",
            "10m wear resistance high pressure hose with stainless steel fittings",
            "Trigger gun with double barrel lance, regulating valve and stainless-steel nozzle",
            "User manual book with parts list",
        ],
        highlights: [
            "Made in Singapore with EU pump",
            "Sturdy full stainless-steel tubular frame with removable handle bar",
            "Triplex ceramic plungers pump",
            "Three-phase 5.5 kW high-efficiency IP55 Italian motor",
            "Unloader valve with pressure release",
            "Stainless steel pressure gauge",
            "Quick connect inlet/outlet coupling",
            "Weather-proof top switch panel with motor overload protection",
            "Thermal protection valve to protect damage to pump seals",
        ],
    },
    {
        id: "UJ20_350",
        image: UJ20_350,
        title: "ULTRAJET UJ20-350",
        description: "is a professional heavy-duty High Pressure Water Blaster with a high performance crank shaft, ceramic pistons and the simplest design – aimed to reduce the Total Cost of Ownership (TCOO). It is suitable for a wide range of applications, and extremely mobile and compact, it is designed to reduce the complexity of maintenance without compromising the quality and durability for heavy duty usage under the toughest conditions.",
        tableData: [
            ["Power supply", "3~/440/60 Hz", ""],
            ["Debit", "20.4", "l/min"],
            ["Work pressure", "350", "bar"],
            ["Max. Temperature of the inlet water (°C)", "65", ""],
            ["Power Consumption", "13.2", "kW"],
            ["Weight", "105", "Kg"],
            ["Dimensions: L x W x H", "800 x 580 x 980", "mm"],
        ],
        equipment: [
            "5m 4-core oil resistance electric cable with 4-pin plug",
            "20m wear resistance high pressure hose with stainless steel fittings",
            "High pressure gun with 900mm lance and stainless-steel nozzle",
            "User manual book with parts list",
        ],
        highlights: [
            "Made in EU with 100% EU components",
            "Sturdy full stainless-steel tubular frame with removable handle bar",
            "Triplex ceramic plunger pump",
            "Asynchronous three-phase 11kW high-efficiency IP55 motor",
            "Low water inlet pressure protection switch and alarm",
            "Unloader valve with pressure release",
            "Stainless steel pressure gauge with glycerin",
            "300 microns water filter with replaceable cartridge",
            "Control panel with emergency stop and low water inlet pressure sensor error light",
            "Thermal protection valve to protect damage to pump seals",
        ],
    },
    {
        id: "UJ20_500",
        image: UJ20_500,
        title: "ULTRAJET UJ20-500",
        description: "is a professional heavy-duty High Pressure Water Blaster with a high performance crank shaft, ceramic pistons and the simplest design – aimed to reduce the Total Cost of Ownership (TCOO). It is suitable for a wide range of applications, and extremely mobile and compact, it is designed to reduce the complexity of maintenance without compromising the quality and durability for heavy duty usage under the toughest conditions.",
        tableData: [
            ["Power supply", "3~/440/60 Hz", ""],
            ["Debit", "18", "l/min"],
            ["Work pressure", "500", "bar"],
            ["Max. Temperature of the inlet water (°C)", "65", ""],
            ["Power Consumption", "15.0", "kW"],
            ["Weight", "170", "Kg"],
            ["Dimensions: L x W x H", "1030 x 687 x 860", "mm"],
        ],
        equipment: [
            "5m 4-core oil resistance electric cable with 4-pin plug",
            "20m wear resistance high pressure hose with stainless steel fittings",
            "1m high pressure gun with lance and stainless steel nozzle",
            "User manual book with parts list",
        ],
        highlights: [
            "Made in EU with 100% EU components",
            "Sturdy full stainless-steel tubular frame with removable handle bar",
            "Triplex ceramic plunger pump",
            "Asynchronous three-phase 15kW high-efficiency IP55 motor",
            "Low water inlet pressure protection switch and alarm",
            "Unloader valve with pressure release",
            "Stainless steel pressure gauge with glycerin",
            "50 microns water filter with replaceable cartridge",
            "Control panel with emergency stop and low water inlet pressure sensor error light",
            "Thermal protection valve to protect damage to pump seals",
        ],
    },
    {
        id: "UJ40_500",
        image: UJ40_500,
        title: "ULTRAJET UJ40-500",
        description: "is a professional heavy-duty high-pressure water blaster with a high-performance crank shaft, ceramic pistons and the simplest design – aimed to reduce the Total Cost of Ownership (TCOO). Fully designed and manufactured in Singapore, these water blasters are designed to reduce the complexity of maintenance without compromising the quality and durability for heavy duty usage under the toughest conditions.",
        tableData: [
            ["Power supply", "3~/440/60 Hz", ""],
            ["Debit", "30", "l/min"],
            ["Work pressure", "500", "bar"],
            ["Max. Temperature of the inlet water (°C)", "60", ""],
            ["Power Consumption", "28.0", "kW"],
            ["Weight", "440", "Kg"],
            ["Dimensions: L x W x H", "790 x 1300 x 1200", "mm"],
        ],
        equipment: [
            "15m 4-core oil resistance electric cable with 4-pin plug",
            "20m wear resistance high pressure hose with stainless steel fittings",
            "1m high pressure gun with lance and stainless steel nozzle",
            "User manual book with parts list",
        ],
        highlights: [
            "Made in Singapore with EU pump",
            "Heavy duty structural frame",
            "Ceramic plungers pump",
            "Asynchronous three-phase 30kW high efficiency IP55 motor",
            "Low water inlet pressure protection switch",
            "Rupture disc system",
            "Stainless steel pressure gauge with glycerin",
            "50 microns’ water filter with replaceable cartridge",
            "Waterproof star-delta control panel with E stop and trip warning light",
        ],
    },
    {
        id: "UJ40_800",
        image: UJ40_800,
        title: "ULTRAJET UJ40-800",
        description: "is a professional heavy-duty high-pressure water blaster with a high-performance crank shaft, ceramic pistons and the simplest design – aimed to reduce the Total Cost of Ownership (TCOO). Fully designed and manufactured in Singapore, these water blasters are designed to reduce the complexity of maintenance without compromising the quality and durability for heavy duty usage under the toughest conditions.",
        tableData: [
            ["Power supply", "3~/440/60 Hz", ""],
            ["Debit", "18", "l/min"],
            ["Work pressure", "800", "bar"],
            ["Max. Temperature of the inlet water (°C)", "35", ""],
            ["Power Consumption", "28.0", "kW"],
            ["Weight", "480", "Kg"],
            ["Dimensions: L x W x H", "790 x 1300 x 1200", "mm"],
        ],
        equipment: [
            "15m 4-core oil resistance electric cable with 4-pin plug",
            "20m wear resistance high pressure hose with stainless steel fittings",
            "1m high pressure gun with lance and stainless steel nozzle",
            "User manual book with parts list",
        ],
        highlights: [
            "Made in Singapore with EU pump",
            "Heavy duty structural frame",
            "Triplex tungsten carbide plunger pump",
            "Asynchronous three-phase 30kW high efficiency IP55 motor",
            "Low water inlet pressure protection switch",
            "Rupture disc system",
            "Booster pump with pressure gauge",
            "Stainless steel pressure gauge with glycerin",
            "50 microns’ water filter with replaceable cartridge",
            "Waterproof star-delta control panel with E stop and trip warning light",
        ],
    },
    {
        id: "UJ40_1000",
        image: UJ40_1000,
        title: "ULTRAJET UJ40-1000",
        description: "is a professional heavy-duty high-pressure water blaster with a high-performance crank shaft, ceramic pistons and the simplest design – aimed to reduce the Total Cost of Ownership (TCOO). Fully designed and manufactured in Singapore, these water blasters are designed to reduce the complexity of maintenance without compromising the quality and durability for heavy duty usage under the toughest conditions.",
        tableData: [
            ["Power supply", "3~/440/60 Hz", ""],
            ["Debit", "18", "l/min"],
            ["Work pressure", "1000", "bar"],
            ["Max. Temperature of the inlet water (°C)", "35", ""],
            ["Power Consumption", "44.0", "kW"],
            ["Weight", "480", "Kg"],
            ["Dimensions: L x W x H", "790 x 1300 x 1200", "mm"],
        ],
        equipment: [
            "15m 4-core oil resistance electric cable with 4-pin plug",
            "20m wear resistance high pressure hose with stainless steel fittings",
            "1m high pressure gun with lance and stainless steel nozzle",
            "User manual book with parts list",
        ],
        highlights: [
            "Made in Singapore with EU pump",
            "Heavy duty structural frame",
            "Triplex tungsten carbide plunger pump",
            "Asynchronous three-phase 30kW high efficiency IP55 motor",
            "Low water inlet pressure protection switch",
            "Rupture disc system",
            "Booster pump with pressure gauge",
            "Stainless steel pressure gauge with glycerin",
            "50 microns’ water filter with replaceable cartridge",
            "Waterproof star-delta control panel with E stop and trip warning light",
        ],
    },
];

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root products={PRODUCTS} />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Home products={PRODUCTS} title="SVC - Home" />,
            },
            {
                path: "/products-list",
                element: (
                    <ProductsList products={PRODUCTS} title="SVC - Products" />
                ),
            },
            {
                path: "/products-list/:id",
                element: <Product products={PRODUCTS} />,
            },
            { path: "/about", element: <About title="SVC - About us" /> },
            {
                path: "/contacts",
                element: <Contacts title="SVC - Contact us" />,
            },
        ],
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
