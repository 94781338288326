import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import adaptor from "../assets/images/products/fittings-and-couplings/adaptor.jpg";
import adaptor2 from "../assets/images/products/fittings-and-couplings/adaptor.-2.jpg";
import reducingNipple from "../assets/images/products/fittings-and-couplings/reducing-nipple.jpg";
import nipple from "../assets/images/products/fittings-and-couplings/nipple.jpg";
import lowCoupling from "../assets/images/products/fittings-and-couplings/low-pressure-quick-coupling.jpg";
import highCoupling from "../assets/images/products/fittings-and-couplings/high-pressure-quick-coupling.jpg";
import hoseNipple from "../assets/images/products/fittings-and-couplings/hose-nipple.jpg";
import hoseCoupler from "../assets/images/products/fittings-and-couplings/hose-coupler-60k-psi.jpg";
import shortLance from "../assets/images/products/guns-and-lances/400mm-short-lance.jpg";
import lance1000mm from "../assets/images/products/guns-and-lances/1000mm-lance.jpg";
import doubleLance from "../assets/images/products/guns-and-lances/double-barrel-lance.jpg";
import dryGun from "../assets/images/products/guns-and-lances/Dry-shut-gun.jpg";
import dumpGun from "../assets/images/products/guns-and-lances/Dump-gun.jpg";
import singleLance from "../assets/images/products/guns-and-lances/single-barrel-lance.jpg";
import ml955m22 from "../assets/images/products/guns-and-lances/ml955-m22.jpg";
import ml955 from "../assets/images/products/guns-and-lances/ml955.jpg";
import ml956 from "../assets/images/products/guns-and-lances/ml956-inox.jpg";
import ml320 from "../assets/images/products/guns-and-lances/ml320.jpg";
import mv925 from "../assets/images/products/guns-and-lances/mv925.jpg";
import rl26 from "../assets/images/products/guns-and-lances/rl26.jpg";
import rl30 from "../assets/images/products/guns-and-lances/rl30.jpg";
import rl56 from "../assets/images/products/guns-and-lances/rl-56.jpg";
import rl57 from "../assets/images/products/guns-and-lances/rl57.jpg";
import rl82 from "../assets/images/products/guns-and-lances/rl82.jpg";
import rl84 from "../assets/images/products/guns-and-lances/rl84.jpg";
import rl1000 from "../assets/images/products/guns-and-lances/rl1000.jpg";
import DN12 from "../assets/images/products/hoses-and-hose-reels/image-1.jpg";
import hoseReel from "../assets/images/products/hoses-and-hose-reels/image-2.jpg";
import psiap4 from "../assets/images/products/nozzles/20k-psiap4-nozzles.jpg";
import psi40k from "../assets/images/products/nozzles/40k-psi-uhp-sapphire-nozzle.jpg";
import jetNozzles from "../assets/images/products/nozzles/high-pressure-straight-fan-jet-nozzles.jpg";
import lowPressure from "../assets/images/products/nozzles/low-pressure-nozzles.jpg";
import rotating350 from "../assets/images/products/nozzles/rotating-nozzle-350bar.jpg";
import rotating400 from "../assets/images/products/nozzles/rotating-nozzle-400bar.jpg";
import rotating500 from "../assets/images/products/nozzles/rotating-nozzle-500bar.jpg";
import rotating700 from "../assets/images/products/nozzles/rotating-nozzle-700bar.jpg";
import rotating800 from "../assets/images/products/nozzles/rotating-nozzle-800bar.jpg";
import waterSeal from "../assets/images/products/spares/water-seal-kit.jpg";
import valveKit from "../assets/images/products/spares/valve-kit.jpg";
import pistonKit from "../assets/images/products/spares/piston-kit.jpg";
import brassKit from "../assets/images/products/spares/Brass-o-ring-kit.jpg";
import vhp80 from "../assets/images/products/valve-and-rupture-discs/vhp80.jpg";
import vhp51 from "../assets/images/products/valve-and-rupture-discs/vhp51.jpg";
import vb350_1 from "../assets/images/products/valve-and-rupture-discs/vb350-1.jpg";
import vb350_2 from "../assets/images/products/valve-and-rupture-discs/vb350-2.jpg";
import vb60 from "../assets/images/products/valve-and-rupture-discs/vb60.jpg";
import vb53 from "../assets/images/products/valve-and-rupture-discs/vb53.jpg";
import vb36ht from "../assets/images/products/valve-and-rupture-discs/vb36ht.jpg";
import svl70 from "../assets/images/products/valve-and-rupture-discs/svl70.jpg";
import svl50 from "../assets/images/products/valve-and-rupture-discs/svl50.jpg";
import helmet from "../assets/images/products/other/helmet.jpg";
import overall from "../assets/images/products/other/overall.jpg";
import apron500bar from "../assets/images/products/other/apron-500bar.jpg";
import gloves from "../assets/images/products/other/gloves.jpg";
import boots from "../assets/images/products/other/boots.jpg";
import gammaKit from "../assets/images/products/other/gamma-kit.jpg";
import apron1000bar from "../assets/images/products/other/apron-1000bar.jpg";
import gaiters from "../assets/images/products/other/gaiters.jpg";
import wetKit from "../assets/images/products/other/wet-sandblasting-kit.jpg";
import multimodeValve from "../assets/images/products/other/multimode-valve.jpg";
import cargoGun from "../assets/images/products/other/cargo-hold-cleaning-gun.jpg";
import flowDivider from "../assets/images/products/other/flow-divider-valves.jpg";
import footValve from "../assets/images/products/other/foot-valve.jpg";
import pipeCleaningNozzles from "../assets/images/products/other/pipe-cleaning-nozzles.jpg";

const SUB_PRODUCTS = [
    {
        id: "2",
        name: "Fittings and couplings",
        products: [
            { image: adaptor, title: "Adaptor" },
            { image: adaptor2, title: "Adaptor 2" },
            { image: reducingNipple, title: "Reducing Nipple" },
            { image: nipple, title: "Nipple" },
            { image: lowCoupling, title: "Low Pressure Quick Coupling" },
            { image: highCoupling, title: "High Pressure Quick Coupling" },
            { image: hoseNipple, title: "Hose Nipple" },
            { image: hoseCoupler, title: "Hose Coupler 60Kpsi" },
        ],
        categoryDesc: <><strong>SV Cleaning</strong> offers a wide selection of fittings and ball/screw type quick couplings that allow the user to easily connect the various components of the high pressure water machine.</>
    },
    {
        id: "3",
        name: "Guns and lances",
        products: [
            { image: shortLance, title: "400mm Short lance-(500Bars 100L)" },
            { image: lance1000mm, title: "1000mm Lance 500bars 100L" },
            { image: doubleLance, title: "Double Barrel Lance-(280Bar 40L)" },
            { image: dryGun, title: "Dry Shut Gun 15K to 40K PSI" },
            { image: dumpGun, title: "Dump Gun 15K to 40K PSI" },
            { image: singleLance, title: "Single Barrel Lance-(280Bar-40L)" },
            { image: ml955m22, title: "ML955 M22-(360Bar 40L)" },
            { image: ml955, title: "ML955-(310Bar 40L)" },
            { image: ml956, title: "ML956 INOX-(360Bar 40L)" },
            { image: ml320, title: "ML320-(160Bar 25L)" },
            { image: mv925, title: "MV925-(280Bar 30L)" },
            { image: rl26, title: "RL26-(250Bar 30L)" },
            { image: rl30, title: "RL30-(310Bar 40L)" },
            { image: rl56, title: "RL56-(350Bar-30L)" },
            { image: rl57, title: "RL57-(500Bar-28L)" },
            { image: rl82, title: "RL82-(180Bar-80L)" },
            { image: rl84, title: "RL84-(500Bar-80L)" },
            { image: rl1000, title: "RL1000-(1000Bar-40L)" },
        ],
        categoryDesc: <>Hand held by the user, the high pressure gun allows the water flow to be interrupted instantly by releasing the trigger. <br/> Reduced opening force of the trigger and ergonomic design are the main features that reduces user’s fatigue even in the event of very high pressure and high water flow. <br /> Strict quality and construction standards guarantee the safety and the efficiency of the products offered by <strong>SV Cleaning</strong>.</>
    },
    {
        id: "4",
        name: "Hoses and hose reels",
        products: [
            { image: DN12, title: "500Bar DN12 ½" },
            {
                image: hoseReel,
                title: "Hose Reel (Water High Pressure Up to 600Bar)",
            },
        ],
        categoryDesc: <><span>Hoses</span> <br/> <strong>SV Cleaning</strong> is able to supply a range of high pressure water hoses (from 250Bar to 4000Bar). The various range of hoses are also available with or without the fittings. <br /> <br /> <span>Hose reels</span> <br /> The range offered by <strong>SV Cleaning</strong> includes manual hose reels and automatic spring-loaded hose reels, possibly complete with a hose guide system for rewinding and positioning the hose itself. Motorized hose reels are also available upon request. A range of highly professional products able to meet the specific needs of the user.</>
    },
    {
        id: "5",
        name: "Nozzles and cleaning heads",
        products: [
            { image: psiap4, title: "20K PSI AP4 Nozzles" },
            { image: psi40k, title: "40K PSI UHP Sapphire Nozzle" },
            {
                image: jetNozzles,
                title: "High Pressure Straight and Fan Jet Nozzles",
            },
            { image: lowPressure, title: "Low pressure nozzles" },
            { image: rotating350, title: "Rotating Nozzle 350bar" },
            { image: rotating400, title: "Rotating Nozzle 400bar" },
            { image: rotating500, title: "Rotating Nozzle 500bar" },
            { image: rotating700, title: "Rotating Nozzle 700bar" },
            { image: rotating800, title: "Rotating Nozzle 800bar" },
        ],
        categoryDesc: <>The quality and functionality of the entire system depend on a small component: The nozzle. <br /> The high quality and heavy-duty nozzles offered by <strong>SV Cleaning</strong> guarantees a correct water supply and a precise correspondence between pressure and water flow rate in function of the size of the nozzle.</>
    },
    {
        id: "6",
        name: "Pump spares and consumable parts",
        products: [
            { image: waterSeal, title: "Water seal kit" },
            { image: valveKit, title: "Valve kit" },
            { image: pistonKit, title: "Piston kit" },
            { image: brassKit, title: "Brass O-ring kit" },
        ],
        categoryDesc: <>Our range of pump spares offered includes major brands of high pressure water machines that you can find in the market, do enquire more for more information.</>
    },
    {
        id: "7",
        name: "Valve and rupture (bursting) discs",
        products: [
            { image: vhp80, title: "VHP80-(800Bar-80L)" },
            { image: vhp51, title: "VHP51-(500Bar-100L)" },
            { image: vb350_1, title: "VB350-(350Bar-80L)" },
            { image: vb350_2, title: "VB83-(800Bar-60L)" },
            { image: vb60, title: "VB60_600-(600Bars-60L)" },
            { image: vb53, title: "VB53-(500Bar-80L)" },
            { image: vb36ht, title: "VB-36HT-(350Bar-80L)" },
            { image: svl70, title: "SVL70-(720Bar-60L)" },
            { image: svl50, title: "SVL50-(500Bar-100L)" },
        ],
        categoryDesc: <>Pressure control is essential in high pressure water machine. <strong>SV Cleaning</strong> offers a wide range of rupture (bursting) discs and valves that can be conceptually divided into 3 different families. <br /> <br /> <span>By-pass valves</span> <br />It allows adjustment of the working pressure of the high pressure water machine. When the delivery line is close, the water is diverted to the bypass and the pump works in low pressure. <br /> <br /> <span>Pressure regulating valves</span> <br /> It allows regulating of the working pressure of the high pressure water machine but, unlike the by-pass valves, when the delivery line is close, the pump continue to work in high pressure. <br /> They are also available in the pneumatic control version (VRPP) for remote adjustment of the working pressure. <br /> <br /> <span>Relief valves</span> <br /> It protects the high pressure water machine from possible over pressurization. When the regulating pressure is exceeded, it will discharge part of the water in by-pass, thus obtaining a pressure drop in the system. But during the standard operation of the high pressure water machine, it will remain close. <br /> <br /> <span>Rupture (bursting) discs</span> <br/>A rupture disc, also known as a pressure safety disc, burst disc, bursting disc, or burst diaphragm, is a non-reclosing pressure relief safety device that, in most uses, protects a pressure vessel, equipment or system from possible over pressurization.</>
    },
    {
        id: "8",
        name: "Others",
        products: [
            { image: helmet, title: "Helmet with visor and ear muffs" },
            { image: overall, title: "Overall (500Bar)" },
            { image: apron500bar, title: "Apron (500Bar)" },
            { image: gloves, title: "Gloves (500Bar)" },
            { image: boots, title: "Boots (800Bar)" },
            { image: gammaKit, title: "Gamma kit (1000/2000/3000Bar)" },
            { image: apron1000bar, title: "Apron (1000/2000/3000Bar)" },
            { image: gaiters, title: "Gaiters (2000/3000Bar)" },
            { image: wetKit, title: "Wet sandblasting kit" },
            { image: multimodeValve, title: "Multi-mode valve" },
            { image: cargoGun, title: "Cargo hold cleaning gun (Toby gun)" },
            { image: flowDivider, title: "Flow divider valves" },
            { image: footValve, title: "Foot valve" },
            { image: pipeCleaningNozzles, title: "Pipe cleaning nozzles" },
        ],
        categoryDesc: <><strong>SV Cleaning</strong> supply high pressure water blasting safety gears (PPE), tumble boxes, foot valves, flow divider valves, wet sandblasting kit, cargo hold cleaning gun (Toby Gun) and more.</>
    },
];

export const Categories = (props) => {
    const [activeTab, setActiveTab] = useState("1");

    const changeTabHandler = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const navigate = useNavigate();
    
    const navigateHandler = (id) => {
        navigate(`/products-list/${id}`);
    };

    return (
        <Container>
            <section className="section_tabs">
                <h2 className="text-center">Product categories</h2>

                <ScrollAnimation animateIn="animate__bounceInLeft" animateOut="animate__bounceOutLeft" animateOnce={true}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === "1" ? "active" : null}
                                onClick={() => changeTabHandler("1")}
                            >
                                High pressure water machines
                            </NavLink>
                        </NavItem>
                        {SUB_PRODUCTS.map((category) => (
                            <NavItem key={category.id}>
                                <NavLink
                                    className={
                                        activeTab === category.id ? "active" : null
                                    }
                                    onClick={() => changeTabHandler(category.id)}
                                >
                                    {category.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </ScrollAnimation>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <p className="category_desc"><strong>Unbeatable quality!</strong> High-pressure cleaning machines from ASEA Marine. Our long experience in the high pressure industry has enabled us to create a range of water blasters and accessories, suitable for numerous applications in the industrial and marine industries. Greater cleaning performance with less consumption. Longer service life with shorter cleaning times. Find out more about our equipment here.</p>

                        <Row>
                            {props.products.map((product) => (
                                <Col sm="4" className="g-4" key={product.id}>
                                    <Card
                                        className="h-100"
                                        onClick={() => navigateHandler(product.id)}
                                    >
                                        <img
                                            alt={product.id}
                                            src={product.image}
                                        />
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                {product.title}
                                            </CardTitle>
                                            <CardText className="text-truncate">
                                                {product.description}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <p className="mt-5"><i>*If you don’t see the products you require below, please kindly contact us through email or call us for further discussion with our technical sales team.</i></p>
                    </TabPane>
                    {SUB_PRODUCTS.map((category) => (
                        <TabPane tabId={category.id} key={category.id}>
                            <p className="category_desc">{category.categoryDesc}</p>

                            <Row>
                                {category.products.map((product) => (
                                    <Col
                                    xs="6"
                                    sm="4"
                                    md="3"
                                    className="g-4"
                                    key={product.title}
                                    >
                                        <Card
                                            className="h-100"
                                            style={{ cursor: "initial" }}
                                            >
                                            <img
                                                alt={product.title}
                                                src={product.image}
                                                />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    {product.title}
                                                </CardTitle>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            <p className="mt-5"><i>*If you don’t see the parts you require below, please kindly contact us through email or call us for further discussion with our technical sales team.</i></p>
                        </TabPane>
                    ))}
                </TabContent>
            </section>
        </Container>
    );
};
