import React from "react";
import styles from './Hero.module.css'

export const Hero = (props) => {
    return (
        <div className={styles.hero}>
            <div className={styles.hero_bg_image} style={{ backgroundImage: `url(${props.heroImage})` }}></div>
            
            <div className={styles.hero_content}>
                <h1>{props.title}</h1>

                <p>{props.subTitle}</p>
            </div>
        </div>
    )
};
