import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Subscribe.module.css'
import image from '../assets/images/patterns/pattern2-2-dark-blue.png'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export const Subscribe = (props) => {
  return (
    <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
        <section className={styles.section_subscribe} style={{backgroundImage: `url(${image})`}}>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-lg-12">
                        <h3 className="display-5 mb-3">{props.title}</h3>

                        <p>{props.subTitle}</p>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <Link to={props.path} className="btn btn-primary btn-lg px-4 me-md-2">{props.btn}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </ScrollAnimation>
  )
}

