import React, { Fragment, useEffect } from "react";
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Col,
    Container,
    List,
    Row,
    Table,
    UncontrolledAccordion,
} from "reactstrap";
import { Subscribe } from "../components/Subscribe";
import { useParams } from "react-router-dom";

export const Product = (props) => {
    const { id } = useParams();

    useEffect(() => {
        document.title = `SVC - ${id}`;
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="section_product">
                <Container>
                    <Row>
                        {props.products.map((product) =>
                            product.id === id ? (
                                <Fragment key={product.id}>
                                    <Col md="12" lg="6">
                                        <div className="product-image">
                                            <img
                                                src={product.image}
                                                alt={product.id}
                                            />
                                        </div>
                                    </Col>

                                    <Col md="12" lg="6">
                                        <div className="product-content">
                                            <div className="description">
                                                <p>
                                                    <strong>
                                                        {product.title}
                                                    </strong>{" "}
                                                    {product.description}
                                                </p>
                                            </div>

                                            <UncontrolledAccordion
                                                defaultOpen={["1"]}
                                                stayOpen
                                            >
                                                <AccordionItem>
                                                    <AccordionHeader targetId="1">
                                                        Specifications
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId="1">
                                                        <Table bordered>
                                                            <thead>
                                                                <tr>
                                                                    <th>Technical data</th>
                                                                    <th>{product.id}</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    product.tableData.map((row, index) => (
                                                                        <tr key={index}>
                                                                            {
                                                                                row.map((cell, index) => (
                                                                                    <td key={index}>{cell}</td>
                                                                                ))
                                                                            }
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionHeader targetId="2">
                                                        Standard equipment
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId="2">
                                                        <List>
                                                            {
                                                                product.equipment.map((line, index) => (
                                                                    <li key={index}>{line}</li>
                                                                ))
                                                            } 
                                                        </List>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionHeader targetId="3">
                                                        Highlights
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId="3">
                                                        <List>
                                                            {
                                                                product.highlights.map((line, index) => (
                                                                    <li key={index}>{line}</li>
                                                                ))
                                                            } 
                                                        </List>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </UncontrolledAccordion>
                                        </div>
                                    </Col>
                                </Fragment>
                            ) : null
                        )}
                    </Row>
                </Container>
            </section>

            <Subscribe path='/contacts' btn='Get in touch' title="Contact us to get your offer!" subTitle='Click the button below and ask us about all the additional information you may need. We would be more than happy to assist you!' />
        </>
    );
};
