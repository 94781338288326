import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export const CardBox = (props) => {
    const navigate = useNavigate();

    const navigateHandler = () => {
        navigate(`/products-list/${props.id}`)
    }
    return (
        <ScrollAnimation animateIn="animate__bounceInLeft" animateOut="animate__bounceOutLeft" animateOnce={true}>
            <Card className={`h-100 ${props.className}`} onClick={navigateHandler}>
                <img alt={props.id} src={props.image} />
                <CardBody>
                    <CardTitle tag="h5">{props.title}</CardTitle>
                    <CardText className="text-truncate">{props.desc}</CardText>
                </CardBody>
            </Card>
        </ScrollAnimation>
    );
};
