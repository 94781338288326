import image from "../assets/images/okeykat-JnkAqh47QUs-unsplash.jpg";
import { Hero } from "../components/Hero";
import { Col, Container, Row } from "reactstrap";
import { CardBox } from "../components/CardBox";
import imageAbout from "../assets/images/sign-mockup.jpg";
import { Subscribe } from "../components/Subscribe";
import { useEffect } from "react";
import searchImg from '../assets/images/search.png'

export const Home = (props) => {
    const products = props.products;

    useEffect(() => {
        document.title = props.title;
    }, [props.title])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Hero
                heroImage={image}
                title="Cost-effective marine equipment"
                subTitle="Welcome to the world of high-performance marine equipment that evert vessel needs. Our mission involves helping you keep tanks and cargo holds in top shape, supporting maintenance efforts with the right equipment for optimum operation. We help vessels overcome major challenges and streamline their operation."
            />

            <Container>
                <section className="section_about">
                    <Row className="g-5">
                        <Col sm="12" md="6">
                            <div className="section_image">
                                <img src={imageAbout} alt="store" />
                            </div>
                        </Col>

                        <Col sm="12" md="6">
                            <div className="section_content">
                                <h2>Who we are ?</h2>
                                <p>SV Cleaning Ltd was established in 2020. The company is the official representative for Europe for the production of cleaning equipment of the manufacturer of high-pressure water machines ASEA Marine (Singapore). ASEA Marine is an innovative company, specializing in professional high pressure water blasting machines. Their aim is to become a one stop solution and service when it comes to supplying of high pressure water blasting machines, spares parts, accessories and consumables for their customers worldwide.</p>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section className="section_products">
                    <Row>
                        <h2>Products</h2>
                        {products.map((product) => (
                            <Col
                                lg="4"
                                md="6"
                                xs="12"
                                key={product.id}
                                className="g-5"
                            >
                                <CardBox
                                    id={product.id}
                                    image={product.image}
                                    title={product.title}
                                    desc={product.description}
                                />
                            </Col>
                        ))}
                            <Col
                                lg="4"
                                md="6"
                                xs="12"
                                className="g-5"
                            >
                                <CardBox
                                    id=''
                                    image={searchImg}
                                    title="View all products"
                                    desc="Browse all our products here..."
                                    className='card-search'
                                />
                            </Col>
                    </Row>
                </section>
            </Container>
            
            <Subscribe path='/contacts' btn='Get in touch' title="Contact us to get your offer!" subTitle='Click the button below and ask us about all the additional information you may need. We would be more than happy to assist you!' />
        </div>
    );
};
