import React, { useEffect } from "react";
import image from "../assets/images/alexandra-diaconu-VuBzplNNi0k-unsplash.jpg";
import { Hero } from "../components/Hero";
import { Subscribe } from "../components/Subscribe";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import articleImg1 from '../assets/images/plane.jpg'
import articleImg2 from '../assets/images/pexels-enrique-hoyos-4041587.jpg'
import articleImg3 from '../assets/images/truck.jpg'
import featuretteImg1 from '../assets/images/sign-mockup.jpg'
import featuretteImg2 from '../assets/images/van-mockup.jpg'

export const About = (props) => {
    useEffect(() => {
        document.title = props.title;
    }, [props.title])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Hero
                heroImage={image}
                title="Learn more about us"
                subTitle="Here you can learn more about our company,
                our partners and our goals."
            />

            <section className="section_about-us">
                <div className="container">
                    <div className="row primary">
                        <div className="col-lg-4">
                            <div className="image" style={{backgroundImage: `url(${articleImg1})`}} />

                            <h2 className="fw-normal">Air-freight</h2>

                            <p>Delivery by courier – a faster delivery option. Suitable for small to mid-size shipments. Please ask us for more information.</p>
                        </div>

                        <div className="col-lg-4">
                            <div className="image" style={{backgroundImage: `url(${articleImg2})`}} />

                            <h2 className="fw-normal">Sea-freight</h2>

                            <p>Delivery by sea-freight - a cheaper and more economical option but the there is a longer lead time. Please ask us for more information.</p>
                        </div>

                        <div className="col-lg-4">
                            <div className="image" style={{backgroundImage: `url(${articleImg3})`}} />

                            <h2 className="fw-normal">Local delivery</h2>

                            <p>Local delivery by truck – we are offering a free of charge local delivery to your agents in Singapore for all your orders.</p>
                        </div>
                    </div>

                    <ScrollAnimation animateIn="animate__bounceInLeft" animateOnce={true}>
                        <hr className="featurette-divider" />

                        <div className="row featurette tag">
                            <div className="col-md-7">
                                <h2 className="featurette-heading fw-normal lh-1">The headquarters of SV Cleaning Ltd <span className="text-muted">is in the city of Varna.</span></h2>

                                <p className="lead">Strategically located in Varna – a big maritime hub, we are well placed to service the marine customers via strong connectivity to the rest of the world. We love and live challenges. Whether every day or extraordinary cleaning projects we want to make your everyday life simpler, better and more pleasant with powerful, efficient cleaning solutions. And to help you achieve big results and move things forward. To create and receive value.</p>
                            </div>

                            <div className="col-md-5">
                                <img src={featuretteImg1} alt="featuretteImg1" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" />
                            </div>
                        </div>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="animate__bounceInRight" animateOnce={true}>
                        <hr className="featurette-divider" />

                        <div className="row featurette tag">
                            <div className="col-md-7 order-md-2">
                                <h2 className="featurette-heading fw-normal lh-1">What <span className="text-muted">drives us?</span></h2>

                                <p className="lead">Our clear, simple innovation philosophy: <br /> innovation is what benefits people. We are perfectionists, always rethinking systems and designs. We have the courage to break new ground and the conviction to do things instead of just talking about them. This philosophy results in solutions with convincing performance, quality and handling that are one step ahead of the market. Because for us cleanliness is the best challenge in the world. This is what we work towards. Every day.</p>
                            </div>

                            <div className="col-md-5 order-md-1">
                                <img src={featuretteImg2} alt="featuretteImg2" className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" />
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </section>

            <Subscribe path='/contacts' btn='Get in touch' title="Contact us to get your offer!" subTitle='Click the button below and ask us about all the additional information you may need. We would be more than happy to assist you!' />
        </>
    );
};
