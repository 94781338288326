export const Error = () => {
  return (
    <>
        <main>
            <h1>An error occured!</h1>

            <p>Could not find this page!</p>
        </main>
    </>
  )
}
