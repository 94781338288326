import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./HeaderNav.module.css";
import logo from "../assets/images/horizontal-2-color.svg";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

export const HeaderNav = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const isActiveHandler = () => setIsActive((prevState) => !prevState);

    const closeMobileNav = () => setIsActive(false);

    const toggle = () => setDropdownOpen(!dropdownOpen);
    return (
        <header>
            <div className="container">
                <div className={styles.header}>
                    <div className="logo">
                        <NavLink to="/">
                            <img
                                src={logo}
                                width="200px"
                                height="44px"
                                alt="logo"
                            />
                        </NavLink>
                    </div>
                    <ul
                        className={`${styles.navigationList} ${
                            isActive ? styles.active : null
                        }`}
                    >
                        <li>
                            <NavLink
                                onClick={closeMobileNav}
                                to="/"
                                className={({ isActive }) =>
                                    isActive ? styles.active : undefined
                                }
                            >
                                Home
                            </NavLink>
                        </li>
                        <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle nav caret style={{ padding: "0" }}>
                                Products
                            </DropdownToggle>
                            <DropdownMenu>
                                {props.products.map((product) => (
                                    <DropdownItem key={product.id}>
                                        <NavLink
                                            onClick={closeMobileNav}
                                            style={{ padding: "0.25rem 1rem" }}
                                            to={`/products-list/${product.id}`}
                                        >
                                            {product.title}
                                        </NavLink>
                                    </DropdownItem>
                                ))}
                                <DropdownItem divider />
                                <DropdownItem>
                                    <NavLink
                                        onClick={closeMobileNav}
                                        to="/products-list"
                                        style={{ padding: "0.25rem 1rem" }}
                                    >
                                        View all
                                    </NavLink>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <li>
                            <NavLink
                                onClick={closeMobileNav}
                                to="/about"
                                className={({ isActive }) =>
                                    isActive ? styles.active : undefined
                                }
                            >
                                About us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                onClick={closeMobileNav}
                                to="/contacts"
                                className={({ isActive }) =>
                                    isActive ? styles.active : undefined
                                }
                            >
                                Contacts
                            </NavLink>
                        </li>
                    </ul>
                    <div className="hamburger" onClick={isActiveHandler}>
                        <span className={isActive ? "active" : null}></span>
                        <span className={isActive ? "active" : null}></span>
                        <span className={isActive ? "active" : null}></span>
                    </div>
                </div>
            </div>
        </header>
    );
};
