import React from 'react'
import { Link } from 'react-router-dom'
import footerLogo from '../assets/images/horizontal-1-color.svg'
import pdf from '../assets/Terms & Conditions.pdf'

export const Footer = () => {
  return (
    <section className="footer">
        	<div className="container">
	        	<footer className="d-flex flex-wrap justify-content-between align-items-center my-4 pt-2 border-top">
				    <p className="col-md-4 mb-0 text-muted">&copy; 2023 SVC Ltd</p>

                    <Link to='/' className='p-2'>
                        <img src={footerLogo} width="250px" height="35px" alt="footer_logo" />
                    </Link>

				    <ul className="nav col-md-12 col-lg-4">
						<li className="nav-item">
							<a href="https://www.linkedin.com/company/sv-cleaning-ltd/?viewAsMember=true" rel="noreferrer" target="_blank" className="nav-link px-2 text-muted">
								<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
								  	<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
								</svg>
							</a>
						</li>
						<li className="nav-item"><Link to='/' className='nav-link px-2 text-muted'>Home</Link></li>
						<li className="nav-item"><Link to='/products-list' className='nav-link px-2 text-muted'>Products</Link></li>
						<li className="nav-item"><Link to='/about' className='nav-link px-2 text-muted'>About us</Link></li>
						<li className="nav-item"><Link to='/contacts' className='nav-link px-2 text-muted'>Contacts</Link></li>
						<li className="nav-item"><a href={pdf} rel="noreferrer" className='nav-link px-2 text-muted' target='_blank'>Terms &amp; conditions</a></li>
				    </ul>
				</footer>
        	</div>
        </section>
  )
}
