import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderNav } from "../components/HeaderNav";
import { Footer } from "../components/Footer";

export const Root = (props) => {
    return (
        <>
            <HeaderNav products={props.products} />
            <Outlet />
            <Footer />
        </>
    );
};
